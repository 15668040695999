/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Countdown, { zeroPad } from "react-countdown";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { connect, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import DuplicateLoginErrorComponent from "../../components/DuplicateLoginErrorComponent";
import NotificationUpgradeAccount from "../../components/NotificationUpgradeAccount";
import NotificationsButton from "../../components/button/pagination-button/NotificationsButton";
import PrimaryButtonPageLevel from "../../components/button/primary-button/PrimaryButtonLevelPage";
import SecondaryButtonLevelPage from "../../components/button/secondary-button/SecondaryButtonLevelPage";
import PopupCloseButton from "../../components/button/special-button/PopupCloseButton";
import SelectGradeButton from "../../components/button/special-button/SelectGradeButton";
import BackgroundImageFromUrl from "../../components/parseImage/BackgroundImageFromUrl";
import ImageFromUrl from "../../components/parseImage/ImageFromUrl";
import {
  getListUnit,
  updateGrade,
} from "../../config/ListLesson";
import { getLesson } from "../../config/lesson";
import {
  getCurrentEvent,
  setClearStateDailyLogout,
} from "../../reducers/dailyMissionSlice";
import {
  clearExamQuestion,
  createNewExams,
  getExamQuestion,
  getListExamsInformation,
  getListMonthlyExamsQuestion,
} from "../../reducers/examsQuestionSlice";
import {
  clearStateGrade,
  getAllGrade,
  getGradeUserInfo,
  getListGradeUnits,
  getListLastLesson,
  setIsChangeGrade,
  setLastLesson,
} from "../../reducers/gradesSlice";
import {
  getALesson,
  setCurrentLessonProgress,
} from "../../reducers/questionsSlice";
import { clearStateRoadMap } from "../../reducers/roadmapSlice";
import { LoadingImage } from "../../templates/helper/LoadingImage";
import { getAllImage } from "../../templates/helper/getAllImages";
import jsonParser from "../../templates/helper/jsonParser";
import DailyMissionMainWrapper from "../daily-mission/DailyMissionMainWrapper";
import "./MenuTopBar.styles.css";

const MenuTopBar = ({
  gradeId,
  gradeName,
  listUserInfo,
  lastLesson,
  listGradeUnit,
  gradeCategory,
  image,
  questionDaily,
  currentHP,
  sections,
  listMonthlyExams,
  startHP,
  imageUrls,
  deadline,
  serverTime,
  isChangeGrade,
  handleChangePathName,
  pathname,
  errorGrade,
}) => {
  const [showAccMenu, setShowAccMenu] = useState(false);
  const [hoverAccMenu, setHoverAccMenu] = useState(false);
  const [defaultAccMenu, setDefaultAccMenu] = useState(true);
  const [showGradeCategory, setShowGradeCategory] = useState(false);
  const [selectedName, setSelectedName] = useState("");
  const [showPopupStartDailyMission, setShowPopupStartDailyMission] =
    useState(false);
  const [showPopupSuccessDailyMission] = useState(false);
  const [showPopupCalculatePoints, setShowPopupCalculatePoints] =
    useState(false);
  const [showPopupDailyMission, setShowPopupDailyMission] = useState(false);
  const [showPopupDailyNow, setShowPopupDailyNow] = useState(
    Boolean(localStorage.getItem("showPopupDailyNow"))
  );
  const [isClick, setIsClick] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState("/activities");
  const [isLoading, setIsLoading] = useState(true);
  const [countdown, setCountdown] = useState(null);
  const [apiCalled, setApiCalled] = useState(false);
  const [firstLesson, setFirstLesson] = useState(null);
  const [unitImage, setUnitImage] = useState("");
  const [checkLesson, setCheckLesson] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [showPopupMonthlyExam, setShowPopupMonthlyExam] = useState(false);
  const [isFetchingPremiumStatus] = useState(false);
  const [showImages, setShowImages] = useState(false);
  const [startExam, setStartExam] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const prevRoute = useLocation();
  const cookies = new Cookies();
  const [selectedId, setSelectedId] = useState(cookies.get("gradeId"));
  // const [grades, setGrades] = useState([]);
  const [showPopupCongratulations, setShowPopupCongratulations] =
    useState(false);
  const [showError, setShowError] = useState(false);
  const [showPopupContinue, setShowPopupContinue] = useState(false);
  const [showPopupWarning, setShowPopupWarning] = useState(false);
  const [render, setRender] = useState(false);
  const token = cookies.get("signinUser");
  const MenuPopupDaily = [
    // {
    //   _id: "1",
    //   title: "Khuyến mại",
    //   icon: "/images/menu/icon-heart.svg",
    //   pathName: "/upgrade",
    // },
    {
      _id: "1",
      title: "Nhiệm vụ",
      icon: "/images/menu/icon-game.svg",
      pathName: "/activities",
    },
    {
      _id: "2",
      title: "Thi tháng",
      icon: "/images/menu/icon-exam.svg",
      pathName: "/monthly-exams",
    },
    {
      _id: "3",
      title: "Luyện thi",
      icon: "/images/menu/icon-kurio-daily.svg",
      pathName: "/practice-ikmc",
    },
    {
      _id: "4",
      title: "Luyện tập",
      icon: "/images/menu/icon-pen.svg",
      pathName: "/exercise",
    },
    {
      _id: "5",
      title: "Bài giảng",
      icon: "/images/menu/icon-book.svg",
      pathName: "/list-unit",
    },
  ];

  const [defaultSelected, setDefaultSelected] = useState(
    MenuPopupDaily.map((item) => item.pathName === selectedMenu)
      .flat()
      .indexOf(true)
  );

  const arrayPath = [
    "/account",
    "/profile",
    "/upgrade",
    "/list-unit",
    "/exercise",
    "/activities",
    "/achievement",
    "/",
  ];
  // const { image } = useImageContext();
  const onHandleClick = () => {
    setShowAccMenu(!showAccMenu);
    setShowGradeCategory(false);
    setDefaultAccMenu(false);
    setHoverAccMenu(false);
  };

  // const onMouseMove = () => {
  //   if (!showAccMenu) {
  //     setDefaultAccMenu(false);
  //     setHoverAccMenu(true);
  //   }
  // };

  // const onMouseOut = () => {
  //   if (!showAccMenu) {
  //     setDefaultAccMenu(true);
  //     setHoverAccMenu(false);
  //   }
  // };

  const getFirstLesson = async (unitId) => {
    try {
      const { result } = await getListUnit(unitId);
      setFirstLesson(result.lessons[0]);
    } catch (error) {
      if (error?.response?.status === 409) {
        setShowError(true);
      }
    }
  };

  const onSelectButton = () => {
    setShowGradeCategory(true);
    setShowAccMenu(false);
    setShowImages(false);
  };

  const handleSelect = async (name, id) => {
    if (id === selectedId) {
      setSelectedId("");
      setSelectedName("");
    } else {
      setSelectedId(id);
      setSelectedName(name);
    }
  };

  const handleSubmitSelectedGrade = async () => {
    try {
      await updateGrade({ gradeId: selectedId });
      dispatch(setIsChangeGrade(true));
      dispatch(clearStateRoadMap());
      cookies.set("gradeId", selectedId);
      dispatch(clearStateGrade());
      // dispatch(getGradeUserInfo());
    } catch (error) {
      if (error.response?.status === 409) {
        setShowError(true);
      }
      console.log(error.message);
    }

    dispatch(clearExamQuestion());
    setCountdown(null);
    setDefaultSelected(0);
    setSelectedMenu("/activities");
    setShowGradeCategory(false);
  };

  const handleLogout = () => {
    const listCookies = cookies.getAll();
    localStorage.clear();
    dispatch(clearStateGrade());
    dispatch(setClearStateDailyLogout());
    dispatch(clearStateRoadMap());
    Object.keys(listCookies).forEach((cookie) => {
      cookies.remove(cookie);
    });
    arrayPath
      .forEach((path) => {
        if (cookies.get("signinUser", { path: path })) {
          cookies.remove("signinUser", { path: path });
        }
      })
      ?.then(() => {
        window.location.href =
          process.env.REACT_APP_ENV === "development"
            ? process.env.REACT_APP_DEV_SIGNIN
            : process.env.REACT_APP_SIGNIN;
      })
      .catch((error) => {
        console.error("Error removing cookie:", error);
      });
    const check = arrayPath.every((path) =>
      cookies.get("signinUser", { path: path })
    );
    if (!check) {
      window.location.href =
        process.env.REACT_APP_ENV === "development"
          ? process.env.REACT_APP_DEV_LANDING_PAGE_URL
          : process.env.REACT_APP_LANDING_PAGE_URL;
    }
  };

  const handleClick = (action) => {
    if (action === "/account") {
      navigate("/account");
    }
    if (action === "/user-upgrade") {
      navigate("/upgrade");
    }
    if (action === "/achievement") {
      navigate("/achievement");
    }
    if (action === "/profile") {
      navigate("/profile");
    }
    if (action === "/voucher") {
      navigate("/voucher");
    }
    setShowAccMenu(false);
  };

  const handleClickShowPopupDailyMission = () => {
    // if (!ConstantStrings.HIDE_GRADES.includes(gradeName)) {
    // }
    setShowPopupDailyMission(true);
  };

  const handleClickShowPopupStartDaily = (status) => {
    if (status === 1) {
      localStorage.removeItem("showPopupDailyNow");
      setShowPopupDailyNow(false);
    }

    if (
      !questionDaily ||
      (questionDaily === undefined && questionDaily === null)
    ) {
      alert("Hiện tại chưa có nhiệm vụ hàng ngày");
      return;
    }

    setShowPopupStartDailyMission(true);
    setShowPopupDailyMission(false);

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleCancelPopupDailyMission = (status) => {
    localStorage.removeItem("solutionInfo");
    localStorage.removeItem("examMonthlyNow");
    localStorage.removeItem("listExamInfoMonthly");

    // if (status) {
    //   navigate("/upgrade");
    // }
    setShowPopupDailyMission(false);
    setShowImages(false);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleShowPopupCalculatePoints = (status) => {
    // setShowPopupCalculatePoints(!showPopupCalculatePoints);
    setShowPopupCalculatePoints(!showPopupCalculatePoints);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    if (status === 1) {
      localStorage.removeItem("showPopupDailyNow");
      setShowPopupDailyNow(false);
    }
  };

  function isValidImageAtIndex(list, index) {
    return (
      Array.isArray(list) &&
      list !== undefined &&
      list !== null &&
      list.length > 0 &&
      list[index] &&
      list[index] !== ""
    );
  }

  const onHandleClickMenu = (menu, id) => {
    setSelectedMenu(menu);
    setDefaultSelected(id - 1);
    setShowImages(false);
  };
  const divDefaultStyle = {
    width: "9rem",
    height: "3rem",
    boxShadow: "2px 2px 0px 0px #0A2A66",
    borderRadius: "1rem",
    border: "1.5px solid var(--kurio-main-colors-kurio-main-black, #0A2A66)",
    cursor: "pointer",
  };

  const unDefaultStyle = {
    width: "9rem",
    background: "#D8E1F4",
    height: "3rem",
    borderRadius: "1rem",
    cursor: "pointer",
  };

  const rerender = ({ days, hours, minutes, seconds, completed }) => {
    return (
      <div className="time-content-card">
        {isLoading ? (
          <span className="loader"></span>
        ) : (
          `${zeroPad(days)} ngày ${zeroPad(hours)} : ${zeroPad(
            minutes
          )} : ${zeroPad(seconds)}`
        )}
      </div>
    );
  };

  const handleClickLesson = async (lessonId, status) => {
    if (status === 1) {
      localStorage.removeItem("showPopupDailyNow");
      setShowPopupDailyNow(false);
    }
    const { result: lesson } = await getLesson(lessonId, token);
    const { result } = await getListUnit(lesson?.header?.unit.id);
    if (result !== null) {
      const indexLesson =
        result?.lessons?.findIndex(
          (obj) =>
            obj._id ===
            result?.lessons?.findIndex((obj) => obj._id === lessonId)
        ) > 9
          ? result?.lessons?.findIndex((obj) => obj._id === lessonId)
          : `0` + result?.lessons?.findIndex((obj) => obj._id === lessonId);
      cookies.set("indexLesson", indexLesson);
    }
    if (lessonId !== null) {
      try {
        await dispatch(getALesson({ lessonId }));
        dispatch(setCurrentLessonProgress([lastLesson]));
        localStorage.removeItem("lesson_end_lesson");
        cookies.remove("lessonId");
        if (lessonId !== null) {
          cookies.set("lessonId", lessonId);
        }
        setLoadingImage(true);
        // navigate("/lesson");
      } catch (error) {
        console.log("home", error);
      }
    }
  };

  const checkALesson = async (id) => {
    try {
      const { result: lesson } = await getLesson(id, token);
      const { result } = await getListUnit(lesson.header.unit.id);
      dispatch(setLastLesson({ ...lastLesson, order: lesson.order }));
      setUnitImage(result.image);
      setCheckLesson(false);
    } catch (error) {
      if (error?.response?.status === 409) {
        setShowError(true);
      } else {
        setCheckLesson(true);
      }
    }
  };

  const handleSetLoadingImage = (state) => {
    setLoadingImage(state);
  };

  const handleCancelPopup = (e, status) => {
    if (e === 1) {
      navigate("/monthly-exams");
    } else if (e === 2) {
      navigate("/practice-ikmc");
    } else if (e === 3) {
      navigate("/exercise");
    } else {
    }

    setShowPopupDailyMission(false);
  };

  const handleClickShowPopup = (status) => {
    if (status === 1) {
      localStorage.removeItem("showPopupDailyNow");
      setShowPopupDailyNow(false);
    }

    if (
      listUserInfo?.premiumFrom === null &&
      listUserInfo?.premiumTo === null
    ) {
      setTimeout(() => {
        setStartExam(true);
      }, 300);
      setTimeout(() => {
        localStorage.removeItem("showPopupDailyNow");
        setShowPopupDailyMission(false);
      }, 300);
    } else {
      if (
        listUserInfo?.premiumFrom &&
        listUserInfo?.premiumTo &&
        new Date(listUserInfo?.premiumFrom).getTime() <= new Date().getTime() &&
        new Date(listUserInfo?.premiumTo).getTime() < new Date().getTime()
      ) {
        setTimeout(() => {
          setStartExam(true);
        }, 300);
        setTimeout(() => {
          localStorage.removeItem("showPopupDailyNow");
          setShowPopupDailyMission(false);
        }, 300);
      } else {
        if (listMonthlyExams !== null) {
          const remainingTime =
            new Date(listMonthlyExams[0]?.endDate).getTime() -
            new Date().getTime();
          const minutesTime = Math.floor(remainingTime / (60 * 1000));

          if (minutesTime <= listMonthlyExams[0]?.examTime) {
            setShowPopupWarning(true);
          } else {
            if (
              localStorage.getItem("takeExamIdInfo") ||
              listMonthlyExams[0]?.takeExamId ||
              JSON.parse(localStorage.getItem("listExamInfoMonthly"))[0]
                ?.takeExamId
            ) {
              setShowPopupContinue(true);
            } else {
              setShowPopupMonthlyExam(true);
            }
          }
        }
      }
    }
  };

  const removePopupOutSide = (e) => {
    if (e?.target?.className === "modal is-active modal-background") {
      setShowPopupMonthlyExam(false);
    }
  };

  const handleSetStartExam = (state) => {
    setStartExam(state);
    setShowPopupDailyNow(false);
  };

  const removePopup = () => {
    setShowPopupMonthlyExam(false);
    setShowPopupCongratulations(false);
    setShowPopupWarning(false);
    setShowPopupContinue(false);

    localStorage.setItem("popupCongratulationCheck", false);
  };

  const handleNavigate = () => {
    setShowPopupCongratulations(false);
    localStorage.setItem("popupCongratulationCheck", false);
    localStorage.setItem("showDailyQuiz", true);
    navigate("/achievement");
  };

  const handleClickStartExam = () => {
    // const getLocalStorage =
    //   localStorage.getItem("listExamInfo") &&
    //   JSON.parse(localStorage.getItem("listExamInfo"))[0];

    if (
      (!localStorage.getItem("takeExamIdInfo") ||
        !localStorage.getItem("createExamQuestionId")) &&
      !JSON.parse(localStorage.getItem("listExamInfoMonthly"))[0]?.takeExamId
    ) {
      dispatch(
        createNewExams({
          examsQuestionId: JSON.parse(
            localStorage.getItem("listExamInfoMonthly")
          )[0].id,
          token,
        })
      );

      localStorage.setItem(
        "createExamQuestionId",
        JSON.parse(localStorage.getItem("listExamInfoMonthly"))[0].id
      );

      setTimeout(() => {
        navigate("/monthly-exam-questions", {
          state: { prevRouter: prevRoute.pathname },
        });
      }, 500);
    } else {
      // dispatch(
      //   getExamQuestion({
      //     takeExamsQuestionId: localStorage.getItem("takeExamIdInfo")
      //       ? localStorage.getItem("takeExamIdInfo")
      //       : JSON.parse(localStorage.getItem("listExamInfoMonthly"))[0]
      //           ?.takeExamId,
      //     token,
      //   })
      // );
      dispatch(clearExamQuestion());

      setTimeout(() => {
        navigate("/monthly-exam-questions", {
          state: { prevRouter: prevRoute.pathname },
        });
      }, 500);
    }
  };

  const handleCancelPopupFirstLogin = (status) => {
    // if (status === 1) {
    //   navigate("/upgrade");
    // }
    setRender(!render);
    localStorage.removeItem("showPopupDailyNow");
    setShowPopupDailyNow(false);
  };

  useEffect(() => {
    if (!window.location.pathname.includes("error")) {
      if (gradeCategory === null) {
        dispatch(getAllGrade());
      }
      if (questionDaily === null) {
        dispatch(getCurrentEvent());
      }
      if (listUserInfo === null) {
        dispatch(getGradeUserInfo());
      }
      if (lastLesson === null) {
        dispatch(getListLastLesson());
      }
      if (gradeId && gradeName?.length > 0) {
        // const arrayPathHide = [ "/activities"];
        if (!cookies.get("gradeId")) {
          cookies.set("gradeId", gradeId);
          setSelectedId(cookies.get("gradeId"));
        }
        // if (
        //   arrayPathHide.includes(window.location.pathname) &&
        //   ConstantStrings.HIDE_GRADES.includes(gradeName)
        // ) {
        //   window.location.href = "/";
        // }
        if (listGradeUnit === null) {
          dispatch(getListGradeUnits({ grades: gradeId, page: 1, size: 50 }));
        }
      }

      // const getListGrades = async () => {
      //   try {
      //     const { result } = await getGradeCategory();
      //     setGrades(result);
      //   } catch (error) {
      //     console.log("error", error);
      //   }
      // };

      // getListGrades();
    }
    if (
      (showAccMenu || showGradeCategory || showPopupDailyMission) &&
      pathname !== prevRoute.pathname
    ) {
      setShowAccMenu(false);
      setShowPopupDailyMission(false);
      setShowGradeCategory(false);
      setDefaultAccMenu(false);
      setHoverAccMenu(false);
    }
    handleChangePathName(prevRoute.pathname);

    if (
      lastLesson &&
      lastLesson?._id !== null &&
      unitImage === "" &&
      listGradeUnit?.length > 0
    ) {
      checkALesson(lastLesson._id);
    }
    if (
      listGradeUnit !== undefined &&
      listGradeUnit?.length > 0 &&
      lastLesson &&
      lastLesson?._id === null &&
      firstLesson === null
    ) {
      getFirstLesson(listGradeUnit[0]?._id);
    }

    const closeAccMenu = () => {
      if (showAccMenu) {
        setIsClick(true);
      }
      if (isClick) {
        setShowAccMenu(false);
        setIsClick(false);
      }
    };
    if (localStorage.getItem("showPopupDailyNow") && !showPopupDailyNow) {
      setShowPopupDailyNow(Boolean(localStorage.getItem("showPopupDailyNow")));
    }

    if (showPopupDailyMission || showPopupDailyNow) {
      if (!showImages) {
        setTimeout(() => {
          setShowImages(true);
        }, 1500);
      }
      dispatch(getListMonthlyExamsQuestion({ page: 1, size: 50 }));
      if (listMonthlyExams !== null || listMonthlyExams !== undefined) {
        const endDate = new Date(
          JSON.parse(localStorage.getItem("examMonthlyNow"))?.endDate
        );

        const now = new Date();

        if (endDate && endDate.toString() !== "Invalid Date") {
          const remainingTime = endDate.getTime() - now.getTime();

          if (!isNaN(remainingTime)) {
            if (remainingTime > 0) {
              setCountdown(remainingTime);
            } else {
              setCountdown(0);
            }
          }

          setTimeout(() => {
            setIsLoading(false); // Set isLoading to false after the data has loaded
          }, 300);
        }

        if (
          JSON.parse(localStorage.getItem("listExamInfoMonthly"))?.length > 0
        ) {
          if (
            JSON.parse(localStorage.getItem("listExamInfoMonthly"))[0]
              ?.takeExamId !== null ||
            localStorage.getItem("takeExamIdInfo")
          ) {
            dispatch(
              getListExamsInformation({
                examYearId: JSON.parse(
                  localStorage.getItem("listExamInfoMonthly")
                )[0]?.id,
              })
            );

            dispatch(
              getExamQuestion({
                takeExamsQuestionId:
                  localStorage.getItem("takeExamIdInfo") !== null
                    ? localStorage.getItem("takeExamIdInfo")
                    : JSON.parse(localStorage.getItem("listExamInfoMonthly"))[0]
                        ?.takeExamId,
                token,
              })
            );
          }
        }

        if (!apiCalled) {
          dispatch(getListMonthlyExamsQuestion({ page: 1, size: 50 }));
          setApiCalled(true);
        }
      } else {
        localStorage.removeItem("solutionInfo");
        localStorage.removeItem("examMonthlyNow");
        localStorage.removeItem("listExamInfoMonthly");

        dispatch(getListMonthlyExamsQuestion({ page: 1, size: 50 }));
      }
    }
    window.addEventListener("click", closeAccMenu);
    return () => window.removeEventListener("click", closeAccMenu);
  }, [
    gradeId,
    showAccMenu,
    isClick,
    questionDaily,
    showImages,
    defaultSelected,
    showPopupDailyMission,
    prevRoute.pathname,
    isFetchingPremiumStatus,
    showPopupDailyNow,
  ]);

  if (startExam) {
    if (
      listUserInfo?.premiumFrom === null &&
      listUserInfo?.premiumTo === null
    ) {
      return (
        <NotificationUpgradeAccount
          handleSetShowError={(state) => handleSetStartExam(state)}
          isShowPopup={startExam}
        />
      );
    } else {
      if (
        listUserInfo?.premiumFrom &&
        listUserInfo?.premiumTo &&
        new Date(listUserInfo?.premiumFrom).getTime() <= new Date().getTime() &&
        new Date(listUserInfo?.premiumTo).getTime() < new Date().getTime()
      ) {
        return (
          <NotificationUpgradeAccount
            handleSetShowError={(state) => handleSetStartExam(state)}
            isShowPopup={startExam}
          />
        );
      } else {
        if (
          localStorage.getItem("takeExamIdLPMonthly") ||
          JSON.parse(localStorage.getItem("listExamInfoMonthly"))[0]
            ?.takeExamId !== null ||
          localStorage.getItem("takeExamMonthlyQuestionId") ||
          localStorage.getItem("examYearIdMonthly")
        ) {
          setTimeout(() => {
            navigate("/monthly-exam-questions", {
              state: { prevRouter: prevRoute.pathname },
            });
          }, 500);
        }
      }
    }
    // if (error && error.includes("Bạn không phải là tài khoản premium, không thể làm bài kiểm tra này")) {
    //
    // } else {
    //
    // }
  }

  if (loadingImage) {
    if (sections !== null && sections?.length > 0) {
      const result = getAllImage(sections, "LESSON");
      if (result && result?.length > 0) {
        return (
          <>
            <LoadingImage
              images={result}
              router={"lesson"}
              handleSetLoadingImage={(state) => handleSetLoadingImage(state)}
            />
          </>
        );
      } else {
        navigate("/lesson");
        // setLoadingImage(false);
      }
    }
  }
  if ((errorGrade !== null && errorGrade === 409) || showError) {
    localStorage.setItem("clearAll", true);
    return <DuplicateLoginErrorComponent isShowPopup={true} />;
  }

  return (
    <div
      // onClick={closeDropdown}
      // onBlur={closeDropdown}
      className="wrapper__menu-top-bar is-flex is-relative"
      style={{ zIndex: 3 }}
    >
      {localStorage.getItem("showPopupDailyNow") ? (
        <div
          className="modal is-active modal-background"
          style={{
            opacity: 1,
            zIndex: 1000,
            backgroundImage:
              'url("/images/hub/background-popup-daily-mission.svg")',
            width: "100%",
            height: "100%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "center",
          }}
        >
          <BackgroundImageFromUrl
            objectId={
              defaultSelected === 0
                ? isValidImageAtIndex(imageUrls, 3)
                  ? imageUrls[3]
                  : "admin/frontend-images/hub/background-1-popup-daily-mission.svg"
                : defaultSelected === 1
                ? "admin/frontend-images/hub/background-daily-monthly-exam.svg"
                : defaultSelected === 2
                ? "admin/frontend-images/hub/background-daily-exam-vault.svg"
                : defaultSelected === 3
                ? "admin/frontend-images/hub/background-daily-exercise.svg"
                : defaultSelected === 4
                ? "admin/frontend-images/hub/background-daily-list-unit.svg"
                : ""
            }
            className="is-relative "
            style={{
              width: "56.875rem",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",

              height: "34.125rem",
              backgroundPosition: "center",
              borderRadius: "2.5rem",
              position: "relative",
            }}
          >
            <div>
              <div
                className="is-flex is-flex-direction-row "
                style={{
                  width: "50.5rem",
                  gap: "1rem",
                  borderRadius: "1.5rem",
                  background: "#fff",
                  border:
                    "1.5px solid var(--kurio-main-colors-kurio-main-black, #0A2A66)",
                  textAlign: "center",
                  padding: "0.75rem",
                  zIndex: 10,
                  position: "absolute",
                  bottom: "-2rem",
                  left: "3.3rem",
                }}
              >
                {MenuPopupDaily.map((item, index) => {
                  return (
                    <div
                      key={item._id}
                      onClick={() => onHandleClickMenu(item.pathName, item._id)}
                      className="is-flex is-flex-direction-column is-justify-content-center"
                      style={
                        item.pathName === selectedMenu
                          ? divDefaultStyle
                          : unDefaultStyle
                      }
                    >
                      <p
                        className="is-flex is-align-items-center is-justify-content-center"
                        style={{
                          fontSize: "0.875rem",
                          fontWeight: 700,
                          color: "#0A2A66",
                          lineHeight: "normal",
                          padding: "0.625rem 1rem",
                          gap: "1rem",
                        }}
                      >
                        <img
                          style={{ width: "1.125rem", height: "1.125rem" }}
                          src={item.icon}
                          alt=""
                        />{" "}
                        {item.title}
                      </p>
                    </div>
                  );
                })}
              </div>

              {defaultSelected === 0 ? (
                <div
                  className="is-flex is-justify-content-center"
                  style={{ width: "100%" }}
                >
                  <div>
                    <div
                      style={{
                        position: "absolute",
                        top: "4.44rem",
                        left: "19rem",
                      }}
                    >
                      <img src="/images/hub/messerger-kurio.svg" alt="" />
                    </div>

                    <ImageFromUrl
                      style={{
                        right: "3.3125rem",
                        bottom: "1.1875rem",
                        position: "absolute",
                        width: "42.5625rem",
                      }}
                      className={` popup-daily-mission ${
                        showImages ? " show" : ""
                      }`}
                      objectId={
                        startHP !== 0 &&
                        startHP / 2 < currentHP &&
                        currentHP <= startHP &&
                        questionDaily !== null
                          ? isValidImageAtIndex(imageUrls, 0)
                            ? imageUrls[0]
                            : "admin/frontend-images/hub/0-1 quai vat khoe manh.png"
                          : startHP !== 0 &&
                            currentHP <= startHP / 2 &&
                            questionDaily !== null
                          ? isValidImageAtIndex(imageUrls, 1)
                            ? imageUrls[1]
                            : "admin/frontend-images/hub/0-2 quai vat yeu.png"
                          : currentHP === 0 && questionDaily !== null
                          ? isValidImageAtIndex(imageUrls, 2)
                            ? imageUrls[2]
                            : "admin/frontend-images/hub/0-3 quai vat dau hang.png"
                          : ""
                      }
                      alt=""
                    />

                    <div
                      className="is-flex is-flex-direction-column is-align-items-center"
                      style={{
                        gap: "1rem",
                        position: "absolute",
                        top: "9.19rem",
                        left: "4rem",
                        zIndex: "10",
                      }}
                    >
                      <div className="is-relative is-flex is-flex-direction-row is-justify-content-space-evenly">
                        <ImageFromUrl
                          style={{ cursor: "pointer" }}
                          objectId={
                            (questionDaily !== undefined ||
                              questionDaily !== null) &&
                            questionDaily
                              ?.map(
                                (item) =>
                                  item.correct === true ||
                                  item.correct === false
                              )
                              .flat()
                              .indexOf(false) !== -1
                              ? "admin/frontend-images/hub/button-popup-daily-mission.svg"
                              : "admin/frontend-images/hub/button-finished-success-daily-mission.svg"
                          }
                          alt=""
                          onClick={() => handleClickShowPopupStartDaily(1)}
                        />
                      </div>

                      <div
                        className="is-flex is-flex-direction-row is-align-items-center is-justify-content-center "
                        style={{
                          width: "17.4375rem",
                          background: "#FAFBFC",
                          // position: "absolute",
                          zIndex: 10,
                          height: "2.5rem",
                          // bottom: "9rem",
                          // left: "4.5rem",
                          gap: "0.75rem",
                          borderRadius: "1rem 1rem 1rem 1rem",
                          padding: "0.5rem 0rem",
                          border:
                            "2px solid var(--kurio-main-colors-kurio-main-black, #0A2A66)",
                        }}
                      >
                        <div className="progress-bar-nav-bar">
                          <div
                            className="progress-bar"
                            style={{
                              width: `${(currentHP / startHP) * 100}%`,
                              backgroundColor: "#E34D4D", // Màu xanh cho phần trước
                            }}
                          ></div>
                        </div>

                        <p
                          className="has-text-kurio-main-color-black"
                          style={{
                            fontSize: "1.25rem",
                            fontWeight: 700,
                            textTransform: "uppercase",
                          }}
                        >
                          <b
                            className="has-text-kurio-red-main-accent"
                            style={{ marginRight: "0.3rem" }}
                          >
                            {currentHP}
                          </b>
                          /<b style={{ marginLeft: "0.3rem" }}>{startHP}</b>
                        </p>
                      </div>
                    </div>

                    <img
                      style={{
                        width: "7.5625rem",
                        position: "absolute",
                        top: "2.5rem",
                        right: "2.3125rem",
                        cursor: "pointer",
                      }}
                      onClick={() => handleShowPopupCalculatePoints(1)}
                      src={"/images/hub/button-calculate-points.svg"}
                      alt=""
                    />
                  </div>
                </div>
              ) : defaultSelected === 1 ? (
                <div>
                  <p
                    className="has-text-kurio-main-color-black"
                    style={{
                      position: "absolute",
                      top: "7.9rem",
                      fontWeight: 400,
                      left: "6.9rem",
                      fontSize: "3rem",
                      fontFamily: "Dela Gothic One",
                    }}
                  >
                    Thi tháng
                  </p>

                  <div className="nar-bar-exam-monthly">
                    <div
                      className={
                        (JSON.parse(localStorage.getItem("listExamInfoMonthly"))
                          ?.length > 0 &&
                          JSON.parse(
                            localStorage.getItem("listExamInfoMonthly")
                          )[0]?.takeExamLP !== null) ||
                        (deadline &&
                          serverTime &&
                          new Date(deadline).getTime() -
                            new Date(serverTime).getTime() <=
                            0)
                          ? "add-css-content-card-monthly-exam"
                          : " content-card-monthly-exam"
                      }
                    >
                      <div
                        className={
                          (JSON.parse(
                            localStorage.getItem("listExamInfoMonthly")
                          )?.length > 0 &&
                            JSON.parse(
                              localStorage.getItem("listExamInfoMonthly")
                            )[0]?.takeExamLP !== null) ||
                          (deadline &&
                            serverTime &&
                            new Date(deadline).getTime() -
                              new Date(serverTime).getTime() <=
                              0)
                            ? "time-finished-card"
                            : "add-create-time"
                        }
                      >
                        <p>Còn lại</p>
                      </div>
                      {isLoading ? (
                        <span className="loader"></span>
                      ) : (
                        <>
                          {countdown !== null && (
                            <Countdown
                              date={new Date().getTime() + countdown}
                              renderer={rerender}
                            />
                          )}
                        </>
                      )}

                      {isLoading ? (
                        <span className="loader"></span>
                      ) : (JSON.parse(
                          localStorage.getItem("listExamInfoMonthly")
                        )?.length > 0 &&
                          JSON.parse(
                            localStorage.getItem("listExamInfoMonthly")
                          )[0]?.takeExamLP !== null) ||
                        (deadline &&
                          serverTime &&
                          new Date(deadline).getTime() -
                            new Date(serverTime).getTime() <=
                            0) ? (
                        <ImageFromUrl
                          objectId="admin/frontend-images/hub/button-success-exam-monthly.svg"
                          alt=""
                        />
                      ) : (
                        <PrimaryButtonPageLevel
                          disabled={countdown === 0}
                          onClick={() => handleClickShowPopup(1)}
                          style={{ height: "4rem", borderRadius: "1rem" }}
                        >
                          {JSON.parse(
                            localStorage.getItem("listExamInfoMonthly")
                          )?.length > 0 &&
                          JSON.parse(
                            localStorage.getItem("listExamInfoMonthly")
                          )[0]?.takeExamId
                            ? "Làm Tiếp"
                            : "Làm ngay"}
                        </PrimaryButtonPageLevel>
                      )}
                    </div>
                  </div>

                  <p
                    onClick={() => handleCancelPopup(defaultSelected, 1)}
                    className="has-text-kurio-main-color-black is-flex align-is-align-items-center"
                    style={
                      (JSON.parse(localStorage.getItem("listExamInfoMonthly"))
                        ?.length > 0 &&
                        JSON.parse(
                          localStorage.getItem("listExamInfoMonthly")
                        )[0]?.takeExamLP !== null) ||
                      (deadline &&
                        serverTime &&
                        new Date(deadline).getTime() -
                          new Date(serverTime).getTime() <=
                          0)
                        ? {
                            position: "absolute",
                            bottom: "8.9rem",
                            fontWeight: 500,
                            left: "11.2rem",
                            gap: "0.75rem",
                            fontSize: "1.125rem",
                            cursor: "pointer",
                          }
                        : {
                            position: "absolute",
                            bottom: "7.5rem",
                            fontWeight: 500,
                            left: "11.2rem",
                            gap: "0.75rem",
                            fontSize: "1.125rem",
                            cursor: "pointer",
                          }
                    }
                  >
                    Thông tin <img src="/images/menu/icon-out.svg" alt="" />
                  </p>
                </div>
              ) : defaultSelected === 2 ? (
                <img
                  onClick={() => handleCancelPopup(defaultSelected, 1)}
                  style={{
                    position: "absolute",
                    top: "20.69rem",
                    left: "10rem",
                    cursor: "pointer",
                  }}
                  src="/images/hub/button-click-daily-exam-vault.svg"
                  alt=""
                />
              ) : defaultSelected === 3 ? (
                <div
                  style={{ position: "relative", top: "8.56rem", left: "5rem" }}
                >
                  <div
                    style={{
                      width: "17.5rem",
                      height: "14rem",
                      gap: "1rem",
                      borderRadius: "2.5rem",
                      border:
                        "1.5px solid var(--kurio-main-colors-kurio-main-black, #0A2A66)",
                      boxShadow: "2px 2px 0px 0px #0A2A66",
                      backgroundColor: "#FAFBFC",
                    }}
                    className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
                  >
                    <p
                      className="has-text-kurio-main-color-black"
                      style={{
                        fontSize: "2rem",
                        fontWeight: 400,
                        fontFamily: "Dela Gothic One",
                        lineHeight: "normal",
                      }}
                    >
                      Luyện tập
                    </p>

                    <span
                      className="has-text-kurio-main-color-black"
                      style={{
                        fontSize: "1.125rem",
                        fontWeight: 500,
                        textAlign: "center",
                        width: "11.625rem",
                        lineHeight: "normal",
                      }}
                    >
                      Hoàn thành các bài tập thú vị và kiếm thêm điểm số!
                    </span>
                  </div>

                  <div
                    style={{
                      position: "absolute",
                      bottom: "-2.5rem",
                      left: "1.3rem",
                    }}
                  >
                    <PrimaryButtonPageLevel
                      onClick={() => handleCancelPopup(defaultSelected, 1)}
                      style={{ height: "4rem", borderRadius: "1rem" }}
                    >
                      Làm ngay
                    </PrimaryButtonPageLevel>
                  </div>
                </div>
              ) : defaultSelected === 4 ? (
                <div
                  style={{ position: "relative", top: "8.56rem", left: "5rem" }}
                >
                  <div
                    style={{
                      width: "17.5rem",
                      height: "14rem",
                      gap: "1rem",
                      borderRadius: "2.5rem",
                      border:
                        "1.5px solid var(--kurio-main-colors-kurio-main-black, #0A2A66)",
                      boxShadow: "2px 2px 0px 0px #0A2A66",
                      backgroundColor: "#FAFBFC",
                    }}
                    className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
                  >
                    {lastLesson?._id === null ? (
                      <>
                        <div style={{ width: "9rem" }}>
                          <img
                            style={{
                              position: "absolute",
                              zIndex: 2,
                              top: "-2rem",
                              left: "19rem",
                            }}
                            src="/images/hub/cu-Nam.svg"
                            alt=""
                          />
                          <div className="status-start__grade-unit">
                            <ImageFromUrl
                              style={{
                                position: "absolute",
                                width: "100%",
                                left: "27rem",
                                top: "1rem",
                              }}
                              objectId="admin/frontend-images/hub/image-start-lesson.svg"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="document-grade-unit__home-page">
                          {lastLesson?._id === null ? (
                            <>
                              <div
                                className="is-flex is-flex-direction-column"
                                style={{
                                  gap: "0.75rem",
                                  marginTop: "-1.5rem",
                                  marginLeft: "1rem",
                                }}
                              >
                                <div className="content-document-home-page">
                                  <p
                                    className="document-grade-unit has-text-kurio-main-color-black"
                                    style={{
                                      textAlign: "center",
                                      fontWeight: "bold",
                                      fontSize: "2rem",
                                    }}
                                  >
                                    Bài giảng
                                  </p>
                                </div>

                                <p className="documnent-start-lesson has-text-kurio-main-color-black">
                                  Ôn lại và đào sâu các kiến thức đã học trên
                                  lớp.
                                </p>
                              </div>

                              <div
                                style={{
                                  position: "absolute",
                                  bottom: "-2.5rem",
                                  left: "1.3rem",
                                }}
                              >
                                <PrimaryButtonPageLevel
                                  onClick={() =>
                                    handleClickLesson(firstLesson?._id, 1)
                                  }
                                  style={{
                                    height: "4rem",
                                    borderRadius: "1rem",
                                  }}
                                >
                                  Bắt đầu học
                                </PrimaryButtonPageLevel>
                              </div>
                            </>
                          ) : (
                            <>
                              {checkLesson ? (
                                <>
                                  <div className="content-document-home-page">
                                    <p className="document-grade-unit has-text-kurio-main-color-black">
                                      Bài bạn đang học đang được cập nhật.
                                    </p>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div
                                    className="is-flex is-flex-direction-column"
                                    style={{ gap: "0.75rem" }}
                                  >
                                    <div className="content-document-home-page">
                                      <p
                                        className="document-grade-unit has-text-kurio-main-color-black"
                                        style={{
                                          textAlign: "center",
                                          fontWeight: "bold",
                                          fontSize: "2rem",
                                        }}
                                      >
                                        Bài giảng
                                      </p>
                                    </div>

                                    <p className="documnent-start-lesson has-text-kurio-main-color-black">
                                      Ôn lại và đào sâu các kiến thức đã học
                                      trên lớp.
                                    </p>
                                  </div>

                                  <div
                                    style={{
                                      position: "absolute",
                                      bottom: "-2.5rem",
                                      left: "1.3rem",
                                    }}
                                  >
                                    <PrimaryButtonPageLevel
                                      onClick={() =>
                                        handleClickLesson(firstLesson?._id, 1)
                                      }
                                      style={{
                                        height: "4rem",
                                        borderRadius: "1rem",
                                      }}
                                    >
                                      Bắt đầu học
                                    </PrimaryButtonPageLevel>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <div style={{ width: "9rem" }}>
                          <img
                            style={{
                              position: "absolute",
                              zIndex: 2,
                              top: "-2rem",
                              left: "19rem",
                            }}
                            src="/images/hub/cu-Nam.svg"
                            alt=""
                          />

                          {listGradeUnit &&
                            listGradeUnit?.length > 0 &&
                            lastLesson && (
                              <>
                                <div className="status__grade-unit-narbar">
                                  <p className="title__grade-unit has-text-kurio-main-color-white">
                                    Đang học
                                  </p>
                                  <div className="after-background_grade-unit">
                                    {!checkLesson && (
                                      <ImageFromUrl
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          objectFit: "cover",
                                          borderRadius: "0.5rem",
                                        }}
                                        objectId={
                                          lastLesson?._id !== null
                                            ? checkLesson
                                              ? ""
                                              : lastLesson?.image
                                            : listGradeUnit[0].image
                                        }
                                        alt=""
                                      />
                                    )}
                                  </div>
                                </div>
                              </>
                            )}
                        </div>

                        <div className="document-grade-unit__home-page">
                          {lastLesson?._id === null ? (
                            <>
                              <div
                                className="is-flex is-flex-direction-column"
                                style={{ gap: "0.75rem", marginTop: "-2.5rem" }}
                              >
                                <p
                                  className="document-grade-unit has-text-kurio-main-color-black"
                                  style={{
                                    textAlign: "left",
                                    fontWeight: "bold",
                                    fontSize: "1.5rem",
                                  }}
                                >
                                  Đang học
                                </p>

                                <span className="title-grade-unit__popup-narbar">
                                  <p>
                                    {listGradeUnit?.length > 0 &&
                                      jsonParser(
                                        listGradeUnit[0]?.unitName,
                                        0,
                                        "list-unit-name"
                                      )}
                                    {/* Chương 1 - Bài 1 */}
                                  </p>
                                </span>

                                <div
                                  style={{
                                    fontSize: "1.25rem",
                                    fontWeight: 700,
                                    lineHeight: "1.4rem",
                                    height: "2.75rem",
                                    cursor: "pointer",
                                    display: "-webkit-box",
                                    WebkitLineClamp: 2,
                                    WebkitBoxOrient: "vertical",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                  className="content-document-home-page"
                                >
                                  <p
                                    className="has-text-kurio-main-color-back"
                                    style={{ textAlign: "left" }}
                                  >
                                    {firstLesson?.title}
                                  </p>
                                </div>
                              </div>

                              <div
                                style={{
                                  position: "absolute",
                                  bottom: "-2.5rem",
                                  left: "1.3rem",
                                }}
                              >
                                <PrimaryButtonPageLevel
                                  onClick={() =>
                                    handleClickLesson(lastLesson?._id, 1)
                                  }
                                  style={{
                                    height: "4rem",
                                    borderRadius: "1rem",
                                  }}
                                >
                                  Học tiếp
                                </PrimaryButtonPageLevel>
                              </div>
                            </>
                          ) : (
                            <>
                              {checkLesson ? (
                                <>
                                  <div className="content-document-home-page">
                                    <p className="document-grade-unit has-text-kurio-main-color-black">
                                      Bài bạn đang học đang được cập nhật
                                    </p>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div
                                    className="is-flex is-flex-direction-column is-align-items-flex-start"
                                    style={{
                                      gap: "0.75rem",
                                      marginTop: " -2.5rem",
                                    }}
                                  >
                                    <p
                                      className="document-grade-unit has-text-kurio-main-color-black"
                                      style={{
                                        textAlign: "left",
                                        fontWeight: "bold",
                                        fontSize: "1.5rem",
                                      }}
                                    >
                                      Đang học
                                    </p>

                                    {lastLesson &&
                                      lastLesson?.unitName?.length > 0 &&
                                      lastLesson?.order !== undefined && (
                                        <span
                                          style={{ display: "-webkit-box" }}
                                          className="title-grade-unit__popup-narbar"
                                        >
                                          <p>
                                            {lastLesson &&
                                              lastLesson?.unitName?.length >
                                                0 &&
                                              jsonParser(
                                                lastLesson?.unitName,
                                                0,
                                                "list-unit-name"
                                              )}
                                          </p>
                                        </span>
                                      )}

                                    <div
                                      style={{
                                        fontSize: "1.25rem",
                                        fontWeight: 700,
                                        lineHeight: "1.4rem",
                                        height: "2.75rem",
                                        cursor: "pointer",
                                        display: "-webkit-box",
                                        WebkitLineClamp: 2,
                                        WebkitBoxOrient: "vertical",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      }}
                                      className="content-document-home-page has-text-kurio-main-color-black"
                                    >
                                      <p
                                        // className="document-grade-unit-narbar"
                                        style={{ textAlign: "left" }}
                                      >
                                        {lastLesson?.title}
                                      </p>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      position: "absolute",
                                      bottom: "-2.5rem",
                                      left: "1.3rem",
                                    }}
                                  >
                                    <PrimaryButtonPageLevel
                                      onClick={() =>
                                        handleClickLesson(lastLesson?._id, 1)
                                      }
                                      style={{
                                        height: "4rem",
                                        borderRadius: "1rem",
                                      }}
                                    >
                                      Học tiếp
                                    </PrimaryButtonPageLevel>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>

            <div
              onClick={() => handleCancelPopupFirstLogin(2)}
              style={{
                position: "absolute",
                top: "-2rem",
                left: "-1.5rem",
                zIndex: 9999,
                background: "transparent",
                border: "none",
                width: "5rem",
                fontSize: "2rem",
              }}
            >
              <PopupCloseButton />
            </div>
          </BackgroundImageFromUrl>
        </div>
      ) : (
        ""
      )}

      {showPopupCongratulations && (
        <BackgroundImageFromUrl
          objectId="admin/frontend-images/hub/popup-chuc-mung-dat-thanh-tich.svg"
          className="modal is-active modal-background"
          style={{
            opacity: 1,
            zIndex: 1000,
            width: "100%",
            height: "100%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="is-flex is-flex-direction-row is-align-items-center">
            <div
              className="is-flex is-flex-direction-column"
              style={{ gap: "2.5rem" }}
            >
              <p
                style={{
                  fontSize: "8rem",
                  textAlign: "center",
                  fontFamily: "Sriracha",
                  lineHeight: "87.5%",
                  fontWeight: 400,
                  color: "#FAFBFC",
                }}
              >
                Chúc <br /> mừng!
              </p>

              <p
                style={{
                  textAlign: "center",
                  fontSize: "1.5rem",
                  fontFamily: "Montserrat",
                  lineHeight: "normal",
                  fontWeight: 500,
                  color: "#FAFBFC",
                }}
              >
                Bạn đã đạt được thêm <br />{" "}
                <b>1 huy hiệu cho Bảng Thành Tích!</b>
              </p>

              <div
                className="is-flex is-flex-direction-row"
                style={{ gap: "1rem" }}
              >
                <PrimaryButtonPageLevel
                  style={{
                    height: "4rem",
                    width: "20rem",
                    borderRadius: "1rem",
                    maxWidth: "20rem",
                  }}
                  isDarkColor={true}
                  onClick={handleNavigate}
                >
                  TỚI TRANG THÀNH TÍCH
                </PrimaryButtonPageLevel>
                <PrimaryButtonPageLevel
                  style={{
                    height: "4rem",
                    width: "10rem",
                    borderRadius: "1rem",
                  }}
                  onClick={removePopup}
                >
                  Đóng
                </PrimaryButtonPageLevel>
              </div>
            </div>

            <div style={{ width: "33.22rem" }}>
              <div className="is-relative" style={{ marginLeft: "-3rem" }}>
                <img src="/images/hub/background-congratulations.svg" alt="" />
                <div
                  style={{
                    position: "absolute",
                    left: "6.3rem",
                    top: "7rem",
                  }}
                >
                  <div className="is-relative">
                    <ImageFromUrl
                      style={{
                        position: "absolute",
                        left: "3.3rem",
                        top: "-4rem",
                      }}
                      objectId="admin/frontend-images/hub/image-congratulations-12.svg"
                      alt=""
                    />
                    <ImageFromUrl
                      objectId="admin/frontend-images/hub/bg-laurel.svg"
                      alt=""
                    />
                  </div>

                  <div
                    style={{
                      padding: "1.5rem 4rem",
                      width: "20.5rem",
                      height: "6.625rem",
                      borderRadius: "5rem",
                      border: "8px solid #fed131",
                      margin: "0 auto",
                    }}
                    className="is-flex is-align-items-center has-background-kurio-yellow-light-3"
                  >
                    <p
                      className="has-text-kurio-main-color-black"
                      style={{
                        fontSize: "1.5rem",
                        fontWeight: 700,
                        lineHeight: "normal",
                        textAlign: "center",
                      }}
                    >
                      Hạ gục Quái vật Tháng {new Date().getMonth() + 1}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </BackgroundImageFromUrl>
      )}

      {showPopupDailyMission && (
        <div
          className="modal is-active modal-background"
          style={{
            opacity: 1,
            zIndex: 1000,
            backgroundImage:
              'url("/images/hub/background-popup-daily-mission.svg")',
            width: "100%",
            height: "100%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "center",
          }}
        >
          <BackgroundImageFromUrl
            objectId={
              defaultSelected === 0
                ? isValidImageAtIndex(imageUrls, 3)
                  ? imageUrls[3]
                  : "admin/frontend-images/hub/background-1-popup-daily-mission.svg"
                : defaultSelected === 1
                ? "admin/frontend-images/hub/background-daily-monthly-exam.svg"
                : defaultSelected === 2
                ? "admin/frontend-images/hub/background-daily-exam-vault.svg"
                : defaultSelected === 3
                ? "admin/frontend-images/hub/background-daily-exercise.svg"
                : defaultSelected === 4
                ? "admin/frontend-images/hub/background-daily-list-unit.svg"
                : ""
            }
            className="is-relative "
            style={{
              width: "56.875rem",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",

              height: "34.125rem",
              backgroundPosition: "center",
              borderRadius: "2.5rem",
              position: "relative",
            }}
          >
            <div>
              <div
                className="is-flex is-flex-direction-row "
                style={{
                  width: "51.5rem",
                  gap: "1rem",
                  borderRadius: "1.5rem",
                  background: "#fff",
                  border:
                    "1.5px solid var(--kurio-main-colors-kurio-main-black, #0A2A66)",
                  textAlign: "center",
                  padding: "0.75rem",
                  zIndex: 10,
                  position: "absolute",
                  bottom: "-2rem",
                  left: "3rem",
                }}
              >
                {MenuPopupDaily.map((item, index) => {
                  return (
                    <div
                      key={item._id}
                      onClick={() => onHandleClickMenu(item.pathName, item._id)}
                      className="is-flex is-flex-direction-column is-justify-content-center"
                      style={
                        item.pathName === selectedMenu
                          ? divDefaultStyle
                          : unDefaultStyle
                      }
                    >
                      <p
                        className="is-flex is-align-items-center is-justify-content-center"
                        style={{
                          fontSize: "0.875rem",
                          fontWeight: 700,
                          color: "#0A2A66",
                          lineHeight: "normal",
                          padding: "0.625rem 1rem",
                          gap: "1rem",
                        }}
                      >
                        <img
                          style={{ width: "1.125rem", height: "1.125rem" }}
                          src={item.icon}
                          alt=""
                        />{" "}
                        {item.title}
                      </p>
                    </div>
                  );
                })}
              </div>

              {defaultSelected === 0 ? (
                <div
                  className="is-flex is-justify-content-center"
                  style={{ width: "100%" }}
                >
                  <div>
                    <div
                      style={{
                        position: "absolute",
                        top: "4.44rem",
                        left: "19rem",
                      }}
                    >
                      <img src="/images/hub/messerger-kurio.svg" alt="" />
                    </div>

                    <ImageFromUrl
                      style={{
                        right: "3.3125rem",
                        bottom: "1.1875rem",
                        position: "absolute",
                        width: "42.5625rem",
                      }}
                      className={` popup-daily-mission ${
                        showImages ? " show" : ""
                      }`}
                      objectId={
                        startHP !== 0 &&
                        startHP / 2 < currentHP &&
                        currentHP <= startHP &&
                        questionDaily !== null
                          ? isValidImageAtIndex(imageUrls, 0)
                            ? imageUrls[0]
                            : "admin/frontend-images/hub/0-1 quai vat khoe manh.png"
                          : startHP !== 0 &&
                            currentHP <= startHP / 2 &&
                            questionDaily !== null
                          ? isValidImageAtIndex(imageUrls, 1)
                            ? imageUrls[1]
                            : "admin/frontend-images/hub/0-2 quai vat yeu.png"
                          : currentHP === 0 && questionDaily !== null
                          ? isValidImageAtIndex(imageUrls, 2)
                            ? imageUrls[2]
                            : "admin/frontend-images/hub/0-3 quai vat dau hang.png"
                          : ""
                      }
                      alt=""
                    />

                    <div
                      className="is-flex is-flex-direction-column is-align-items-center"
                      style={{
                        gap: "1rem",
                        position: "absolute",
                        top: "9.19rem",
                        left: "4rem",
                        zIndex: "10",
                      }}
                    >
                      <div className="is-relative is-flex is-flex-direction-row is-justify-content-space-evenly">
                        <ImageFromUrl
                          style={{ cursor: "pointer" }}
                          objectId={
                            (questionDaily !== undefined ||
                              questionDaily !== null) &&
                            questionDaily
                              ?.map(
                                (item) =>
                                  item.correct === true ||
                                  item.correct === false
                              )
                              .flat()
                              .indexOf(false) !== -1
                              ? "admin/frontend-images/hub/button-popup-daily-mission.svg"
                              : "admin/frontend-images/hub/button-finished-success-daily-mission.svg"
                          }
                          alt=""
                          onClick={handleClickShowPopupStartDaily}
                        />
                      </div>

                      <div
                        className="is-flex is-flex-direction-row is-align-items-center is-justify-content-center "
                        style={{
                          width: "17.4375rem",
                          background: "#FAFBFC",
                          // position: "absolute",
                          zIndex: 10,
                          height: "2.5rem",
                          // bottom: "9rem",
                          // left: "4.5rem",
                          gap: "0.75rem",
                          borderRadius: "1rem 1rem 1rem 1rem",
                          padding: "0.5rem 0rem",
                          border:
                            "2px solid var(--kurio-main-colors-kurio-main-black, #0A2A66)",
                        }}
                      >
                        <div className="progress-bar-nav-bar">
                          <div
                            className="progress-bar"
                            style={{
                              width: `${(currentHP / startHP) * 100}%`,
                              backgroundColor: "#E34D4D", // Màu xanh cho phần trước
                            }}
                          ></div>
                        </div>

                        <p
                          className="has-text-kurio-main-color-black"
                          style={{
                            fontSize: "1.25rem",
                            fontWeight: 700,
                            textTransform: "uppercase",
                          }}
                        >
                          <b
                            className="has-text-kurio-red-main-accent"
                            style={{ marginRight: "0.3rem" }}
                          >
                            {currentHP}
                          </b>
                          /<b style={{ marginLeft: "0.3rem" }}>{startHP}</b>
                        </p>
                      </div>
                    </div>

                    <img
                      style={{
                        width: "7.5625rem",
                        position: "absolute",
                        top: "2.5rem",
                        right: "2.3125rem",
                        cursor: "pointer",
                      }}
                      onClick={handleShowPopupCalculatePoints}
                      src={"/images/hub/button-calculate-points.svg"}
                      alt=""
                    />
                  </div>
                </div>
              ) : defaultSelected === 1 ? (
                <div>
                  <p
                    className="has-text-kurio-main-color-black"
                    style={{
                      position: "absolute",
                      top: "7.9rem",
                      fontWeight: 400,
                      left: "6.9rem",
                      fontSize: "3rem",
                      fontFamily: "Dela Gothic One",
                    }}
                  >
                    Thi tháng
                  </p>

                  <div className="nar-bar-exam-monthly">
                    <div
                      className={
                        (JSON.parse(localStorage.getItem("listExamInfoMonthly"))
                          ?.length > 0 &&
                          JSON.parse(
                            localStorage.getItem("listExamInfoMonthly")
                          )[0]?.takeExamLP !== null) ||
                        (deadline &&
                          serverTime &&
                          new Date(deadline).getTime() -
                            new Date(serverTime).getTime() <=
                            0)
                          ? "add-css-content-card-monthly-exam"
                          : " content-card-monthly-exam"
                      }
                    >
                      <div
                        className={
                          (JSON.parse(
                            localStorage.getItem("listExamInfoMonthly")
                          )?.length > 0 &&
                            JSON.parse(
                              localStorage.getItem("listExamInfoMonthly")
                            )[0]?.takeExamLP !== null) ||
                          (deadline &&
                            serverTime &&
                            new Date(deadline).getTime() -
                              new Date(serverTime).getTime() <=
                              0)
                            ? "time-finished-card"
                            : "add-create-time"
                        }
                      >
                        <p>Còn lại</p>
                      </div>
                      {isLoading ? (
                        <span className="loader"></span>
                      ) : (
                        <>
                          {countdown !== null && (
                            <Countdown
                              date={new Date().getTime() + countdown}
                              renderer={rerender}
                            />
                          )}
                        </>
                      )}

                      {isLoading ? (
                        <span className="loader"></span>
                      ) : (JSON.parse(
                          localStorage.getItem("listExamInfoMonthly")
                        )?.length > 0 &&
                          JSON.parse(
                            localStorage.getItem("listExamInfoMonthly")
                          )[0]?.takeExamLP !== null) ||
                        (deadline &&
                          serverTime &&
                          new Date(deadline).getTime() -
                            new Date(serverTime).getTime() <=
                            0) ? (
                        <ImageFromUrl
                          objectId="admin/frontend-images/hub/button-success-exam-monthly.svg"
                          alt=""
                        />
                      ) : (
                        <PrimaryButtonPageLevel
                          disabled={countdown === 0}
                          onClick={handleClickShowPopup}
                          style={{ height: "4rem", borderRadius: "1rem" }}
                        >
                          {JSON.parse(
                            localStorage.getItem("listExamInfoMonthly")
                          )?.length > 0 &&
                          JSON.parse(
                            localStorage.getItem("listExamInfoMonthly")
                          )[0]?.takeExamId
                            ? "Làm Tiếp"
                            : "Làm ngay"}
                        </PrimaryButtonPageLevel>
                      )}
                    </div>
                  </div>

                  <p
                    onClick={() => handleCancelPopup(defaultSelected)}
                    className="has-text-kurio-main-color-black is-flex align-is-align-items-center"
                    style={
                      (JSON.parse(localStorage.getItem("listExamInfoMonthly"))
                        ?.length > 0 &&
                        JSON.parse(
                          localStorage.getItem("listExamInfoMonthly")
                        )[0]?.takeExamLP !== null) ||
                      (deadline &&
                        serverTime &&
                        new Date(deadline).getTime() -
                          new Date(serverTime).getTime() <=
                          0)
                        ? {
                            position: "absolute",
                            bottom: "8.9rem",
                            fontWeight: 500,
                            left: "11.2rem",
                            gap: "0.75rem",
                            fontSize: "1.125rem",
                            cursor: "pointer",
                          }
                        : {
                            position: "absolute",
                            bottom: "7.5rem",
                            fontWeight: 500,
                            left: "11.2rem",
                            gap: "0.75rem",
                            fontSize: "1.125rem",
                            cursor: "pointer",
                          }
                    }
                  >
                    Thông tin <img src="/images/menu/icon-out.svg" alt="" />
                  </p>
                </div>
              ) : defaultSelected === 2 ? (
                <img
                  onClick={() => handleCancelPopup(defaultSelected)}
                  style={{
                    position: "absolute",
                    top: "20.69rem",
                    left: "10rem",
                    cursor: "pointer",
                  }}
                  src="/images/hub/button-click-daily-exam-vault.svg"
                  alt=""
                />
              ) : defaultSelected === 3 ? (
                <div
                  style={{ position: "relative", top: "8.56rem", left: "5rem" }}
                >
                  <div
                    style={{
                      width: "17.5rem",
                      height: "14rem",
                      gap: "1rem",
                      borderRadius: "2.5rem",
                      border:
                        "1.5px solid var(--kurio-main-colors-kurio-main-black, #0A2A66)",
                      boxShadow: "2px 2px 0px 0px #0A2A66",
                      backgroundColor: "#FAFBFC",
                    }}
                    className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
                  >
                    <p
                      className="has-text-kurio-main-color-black"
                      style={{
                        fontSize: "2rem",
                        fontWeight: 400,
                        fontFamily: "Dela Gothic One",
                        lineHeight: "normal",
                      }}
                    >
                      Luyện tập
                    </p>

                    <span
                      className="has-text-kurio-main-color-black"
                      style={{
                        fontSize: "1.125rem",
                        fontWeight: 500,
                        textAlign: "center",
                        width: "11.625rem",
                        lineHeight: "normal",
                      }}
                    >
                      Hoàn thành các bài tập thú vị và kiếm thêm điểm số!
                    </span>
                  </div>

                  <div
                    style={{
                      position: "absolute",
                      bottom: "-2.5rem",
                      left: "1.3rem",
                    }}
                  >
                    <PrimaryButtonPageLevel
                      onClick={() => handleCancelPopup(defaultSelected)}
                      style={{ height: "4rem", borderRadius: "1rem" }}
                    >
                      Làm ngay
                    </PrimaryButtonPageLevel>
                  </div>
                </div>
              ) : defaultSelected === 4 ? (
                <div
                  style={{ position: "relative", top: "8.56rem", left: "5rem" }}
                >
                  <div
                    style={{
                      width: "17.5rem",
                      height: "14rem",
                      gap: "1rem",
                      borderRadius: "2.5rem",
                      border:
                        "1.5px solid var(--kurio-main-colors-kurio-main-black, #0A2A66)",
                      boxShadow: "2px 2px 0px 0px #0A2A66",
                      backgroundColor: "#FAFBFC",
                    }}
                    className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
                  >
                    {lastLesson?._id === null ? (
                      <>
                        <div style={{ width: "9rem" }}>
                          <img
                            style={{
                              position: "absolute",
                              zIndex: 2,
                              top: "-2rem",
                              left: "19rem",
                            }}
                            src="/images/hub/cu-Nam.svg"
                            alt=""
                          />
                          <div className="status-start__grade-unit">
                            <ImageFromUrl
                              style={{
                                position: "absolute",
                                width: "100%",
                                left: "27rem",
                                top: "1rem",
                              }}
                              objectId="admin/frontend-images/hub/image-start-lesson.svg"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="document-grade-unit__home-page">
                          {lastLesson?._id === null ? (
                            <>
                              <div
                                className="is-flex is-flex-direction-column"
                                style={{
                                  gap: "0.75rem",
                                  marginTop: "-1.5rem",
                                  marginLeft: "1rem",
                                }}
                              >
                                <div className="content-document-home-page">
                                  <p
                                    className="document-grade-unit has-text-kurio-main-color-black"
                                    style={{
                                      textAlign: "center",
                                      fontWeight: "bold",
                                      fontSize: "2rem",
                                    }}
                                  >
                                    Bài giảng
                                  </p>
                                </div>

                                <p className="documnent-start-lesson has-text-kurio-main-color-black">
                                  Ôn lại và đào sâu các kiến thức đã học trên
                                  lớp.
                                </p>
                              </div>

                              <div
                                style={{
                                  position: "absolute",
                                  bottom: "-2.5rem",
                                  left: "1.3rem",
                                }}
                              >
                                <PrimaryButtonPageLevel
                                  onClick={() =>
                                    handleClickLesson(firstLesson?._id)
                                  }
                                  style={{
                                    height: "4rem",
                                    borderRadius: "1rem",
                                  }}
                                >
                                  Bắt đầu học
                                </PrimaryButtonPageLevel>
                              </div>
                            </>
                          ) : (
                            <>
                              {checkLesson ? (
                                <>
                                  <div className="content-document-home-page">
                                    <p className="document-grade-unit has-text-kurio-main-color-black">
                                      Bài bạn đang học đang được cập nhật.
                                    </p>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div
                                    className="is-flex is-flex-direction-column"
                                    style={{ gap: "0.75rem" }}
                                  >
                                    <div className="content-document-home-page">
                                      <p
                                        className="document-grade-unit has-text-kurio-main-color-black"
                                        style={{
                                          textAlign: "center",
                                          fontWeight: "bold",
                                          fontSize: "2rem",
                                        }}
                                      >
                                        Bài giảng
                                      </p>
                                    </div>

                                    <p className="documnent-start-lesson has-text-kurio-main-color-black">
                                      Ôn lại và đào sâu các kiến thức đã học
                                      trên lớp.
                                    </p>
                                  </div>

                                  <div
                                    style={{
                                      position: "absolute",
                                      bottom: "-2.5rem",
                                      left: "1.3rem",
                                    }}
                                  >
                                    <PrimaryButtonPageLevel
                                      onClick={() =>
                                        handleClickLesson(firstLesson?._id)
                                      }
                                      style={{
                                        height: "4rem",
                                        borderRadius: "1rem",
                                      }}
                                    >
                                      Bắt đầu học
                                    </PrimaryButtonPageLevel>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <div style={{ width: "9rem" }}>
                          <img
                            style={{
                              position: "absolute",
                              zIndex: 2,
                              top: "-2rem",
                              left: "19rem",
                            }}
                            src="/images/hub/cu-Nam.svg"
                            alt=""
                          />

                          {listGradeUnit &&
                            listGradeUnit?.length > 0 &&
                            lastLesson && (
                              <>
                                <div className="status__grade-unit-narbar">
                                  <p className="title__grade-unit has-text-kurio-main-color-white">
                                    Đang học
                                  </p>
                                  <div className="after-background_grade-unit">
                                    {!checkLesson && (
                                      <ImageFromUrl
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          objectFit: "cover",
                                          borderRadius: "0.5rem",
                                        }}
                                        objectId={
                                          lastLesson?._id !== null
                                            ? checkLesson
                                              ? ""
                                              : lastLesson?.image
                                            : listGradeUnit[0].image
                                        }
                                        alt=""
                                      />
                                    )}
                                  </div>
                                </div>
                              </>
                            )}
                        </div>

                        <div className="document-grade-unit__home-page">
                          {lastLesson?._id === null ? (
                            <>
                              <div
                                className="is-flex is-flex-direction-column"
                                style={{ gap: "0.75rem", marginTop: "-2.5rem" }}
                              >
                                <p
                                  className="document-grade-unit has-text-kurio-main-color-black"
                                  style={{
                                    textAlign: "left",
                                    fontWeight: "bold",
                                    fontSize: "1.5rem",
                                  }}
                                >
                                  Đang học
                                </p>

                                <span className="title-grade-unit__popup-narbar">
                                  <p>
                                    {listGradeUnit?.length > 0 &&
                                      jsonParser(
                                        listGradeUnit[0]?.unitName,
                                        0,
                                        "list-unit-name"
                                      )}
                                    {/* Chương 1 - Bài 1 */}
                                  </p>
                                </span>

                                <div
                                  style={{
                                    fontSize: "1.25rem",
                                    fontWeight: 700,
                                    lineHeight: "1.4rem",
                                    height: "2.75rem",
                                    cursor: "pointer",
                                    display: "-webkit-box",
                                    WebkitLineClamp: 2,
                                    WebkitBoxOrient: "vertical",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                  className="content-document-home-page"
                                >
                                  <p
                                    className="has-text-kurio-main-color-back"
                                    style={{ textAlign: "left" }}
                                  >
                                    {firstLesson?.title}
                                  </p>
                                </div>
                              </div>

                              <div
                                style={{
                                  position: "absolute",
                                  bottom: "-2.5rem",
                                  left: "1.3rem",
                                }}
                              >
                                <PrimaryButtonPageLevel
                                  onClick={() =>
                                    handleClickLesson(lastLesson?._id)
                                  }
                                  style={{
                                    height: "4rem",
                                    borderRadius: "1rem",
                                  }}
                                >
                                  Học tiếp
                                </PrimaryButtonPageLevel>
                              </div>
                            </>
                          ) : (
                            <>
                              {checkLesson ? (
                                <>
                                  <div className="content-document-home-page">
                                    <p className="document-grade-unit has-text-kurio-main-color-black">
                                      Bài bạn đang học đang được cập nhật
                                    </p>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div
                                    className="is-flex is-flex-direction-column is-align-items-flex-start"
                                    style={{
                                      gap: "0.75rem",
                                      marginTop: " -2.5rem",
                                    }}
                                  >
                                    <p
                                      className="document-grade-unit has-text-kurio-main-color-black"
                                      style={{
                                        textAlign: "left",
                                        fontWeight: "bold",
                                        fontSize: "1.5rem",
                                      }}
                                    >
                                      Đang học
                                    </p>

                                    {lastLesson &&
                                      lastLesson?.unitName?.length > 0 &&
                                      lastLesson?.order !== undefined && (
                                        <span
                                          style={{ display: "-webkit-box" }}
                                          className="title-grade-unit__popup-narbar"
                                        >
                                          <p>
                                            {lastLesson &&
                                              lastLesson?.unitName?.length >
                                                0 &&
                                              jsonParser(
                                                lastLesson?.unitName,
                                                0,
                                                "list-unit-name"
                                              )}
                                          </p>
                                        </span>
                                      )}

                                    <div
                                      style={{
                                        fontSize: "1.25rem",
                                        fontWeight: 700,
                                        lineHeight: "1.4rem",
                                        height: "2.75rem",
                                        cursor: "pointer",
                                        display: "-webkit-box",
                                        WebkitLineClamp: 2,
                                        WebkitBoxOrient: "vertical",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      }}
                                      className="content-document-home-page has-text-kurio-main-color-black"
                                    >
                                      <p
                                        // className="document-grade-unit-narbar"
                                        style={{ textAlign: "left" }}
                                      >
                                        {lastLesson?.title}
                                      </p>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      position: "absolute",
                                      bottom: "-2.5rem",
                                      left: "1.3rem",
                                    }}
                                  >
                                    <PrimaryButtonPageLevel
                                      onClick={() =>
                                        handleClickLesson(lastLesson?._id)
                                      }
                                      style={{
                                        height: "4rem",
                                        borderRadius: "1rem",
                                      }}
                                    >
                                      Học tiếp
                                    </PrimaryButtonPageLevel>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>

            <div
              onClick={() => handleCancelPopupDailyMission(false)}
              style={{
                position: "absolute",
                top: "-2rem",
                left: "-1.5rem",
                zIndex: 9999,
                background: "transparent",
                border: "none",
                width: "5rem",
                fontSize: "2rem",
              }}
            >
              <PopupCloseButton />
            </div>
          </BackgroundImageFromUrl>
        </div>
      )}

      {showPopupContinue && (
        <div
          onClick={removePopupOutSide}
          className="modal is-active modal-background"
          style={{ opacity: 1, zIndex: 1000 }}
        >
          <div className="modal-quiz is-relative">
            <h2 style={{ color: "#0A2A66" }} className="title-popup-quiz">
              ! Thi tháng !
            </h2>
            <p className="documnent-quiz">
              Bạn đang làm <b>Đề thi Tháng {new Date().getMonth()}. </b>
            </p>

            <div className="is-flex " style={{ gap: "1rem", margin: "0 auto" }}>
              <PrimaryButtonPageLevel
                onClick={handleClickStartExam}
                style={{ height: "4rem", borderRadius: "1rem" }}
              >
                Làm tiếp
              </PrimaryButtonPageLevel>
            </div>

            <div
              onClick={removePopup}
              className=""
              style={{
                position: "absolute",
                top: "-8%",
                left: "-4%",
                zIndex: 9999,
                background: "transparent",
                border: "none",
                width: "5rem",
                fontSize: "2rem",
              }}
            >
              <PopupCloseButton />
            </div>
          </div>
        </div>
      )}

      {showPopupWarning && (
        <div
          onClick={removePopupOutSide}
          className="modal is-active modal-background"
          style={{ opacity: 1, zIndex: 1000 }}
        >
          <div className="modal-quiz is-relative">
            <h2 style={{ color: "#0A2A66" }} className="title-popup-quiz">
              ! Lưu ý !
            </h2>
            <p className="documnent-quiz">
              {listMonthlyExams !== null && listMonthlyExams[0]?.name}{" "}
              <b>
                chỉ còn{" "}
                {listMonthlyExams !== null &&
                  Math.floor(
                    (new Date(listMonthlyExams[0]?.endDate).getTime() -
                      new Date().getTime()) /
                      (60 * 1000)
                  )}{" "}
                phút
              </b>
              . <br /> Thời gian làm bài là{" "}
              <b>
                {listMonthlyExams !== null && listMonthlyExams[0]?.examTime}{" "}
                phút
              </b>
              . <br /> Bạn có chắc chắn muốn bắt đầu?
            </p>

            <div
              className="is-flex is-flex-direction-row"
              style={{ gap: "1rem", margin: "0 auto" }}
            >
              <SecondaryButtonLevelPage onClick={removePopup}>
                Để lúc khác
              </SecondaryButtonLevelPage>
              <PrimaryButtonPageLevel
                onClick={handleClickStartExam}
                style={{ height: "4rem" }}
              >
                {localStorage.getItem("takeExamIdInfo") ||
                listMonthlyExams[0]?.takeExamId
                  ? "Làm tiếp"
                  : "Bắt đầu ngay"}
              </PrimaryButtonPageLevel>
            </div>

            <div
              onClick={removePopup}
              className=""
              style={{
                position: "absolute",
                top: "-8%",
                left: "-4%",
                zIndex: 9999,
                background: "transparent",
                border: "none",
                width: "5rem",
                fontSize: "2rem",
              }}
            >
              <PopupCloseButton />
            </div>
          </div>
        </div>
      )}

      {showPopupStartDailyMission && (
        <DndProvider backend={HTML5Backend}>
          <DailyMissionMainWrapper
            showPopupStartDailyMission={showPopupStartDailyMission}
            setShowPopupCalculatePoints={setShowPopupCalculatePoints}
            setShowPopupStartDailyMission={setShowPopupStartDailyMission}
            setShowPopupCongratulations={setShowPopupCongratulations}
            showPopupCongratulations={showPopupCongratulations}
            showPopupSuccessDailyMission={showPopupSuccessDailyMission}
          />
        </DndProvider>
      )}

      {showPopupCalculatePoints && (
        <div
          className="modal is-active modal-background"
          style={{
            opacity: 1,
            zIndex: 1000,
            backgroundImage:
              'url("/images/hub/background-popup-daily-mission.svg")',
            width: "100%",
            height: "100%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "center",
          }}
        >
          <BackgroundImageFromUrl
            objectId="admin/frontend-images/hub/background-calculate-points.svg"
            className="is-relative "
            style={{
              width: "56.875rem",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              // height: "34.125rem",
              borderRadius: "3.8125rem",
              position: "relative",
            }}
          >
            <div
              style={{
                position: "position",
                top: "2rem",
                right: "-2.7rem",
                width: "100%",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  zIndex: "110",
                  top: "-3rem",
                  right: "12rem",
                }}
              >
                <img
                  className="is-relative"
                  src="/images/hub/nav-bar-calculate-points.svg"
                  alt=""
                />
              </div>
            </div>

            <div style={{ padding: "2rem" }}>
              <div
                style={{
                  width: " 52.125rem",
                  height: "30.125rem",
                  borderRadius: "4rem",
                  padding: "5.19rem 6.06rem",
                  background: "#fff",
                  margin: "0 auto",
                }}
              >
                <div
                  className="main-caculator-point"
                  style={{
                    width: "40rem",
                    maxHeight: "22.5rem",
                    overflowX: "hidden",
                    overflowY: "scroll",
                  }}
                >
                  <div style={{ width: "38.5rem", maxHeight: "22rem" }}>
                    <p
                      className="has-text-kurio-main-color-black"
                      style={{
                        fontSize: "1.25",
                        fontWeight: 500,
                        textAlign: "justify",
                      }}
                    >
                      Nhiệm vụ của bạn là đánh bại quái vật để thu thập trọn bộ
                      sticker quái vật của Kurio. <br />
                      1. Quái vật được sinh ra vào ngày đầu tiên của mỗi tháng
                      với 100 điểm sức khỏe. <br />
                      2. Mỗi ngày, bạn sẽ gặp phải 5 rào cản là 5 câu hỏi toán
                      học. Trả lời đúng mỗi câu hỏi sẽ trừ 1 điểm sức khỏe của
                      quái vật. <br />
                      3. Với mỗi ngày bạn không tham gia trận chiến, quái vật sẽ
                      hồi sức! Vậy nên các bạn nhớ tham gia nhiệm vụ hằng ngày
                      nhé! <br />
                      4. Phần thưởng khi đánh bại quái vật là huy hiệu quái vật
                      tương ứng để bạn lưu vào bộ sưu tập của mình. <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              onClick={handleShowPopupCalculatePoints}
              style={{
                position: "absolute",
                top: "-2rem",
                left: "-1.5rem",
                zIndex: 9999,
                background: "transparent",
                border: "none",
                width: "5rem",
                fontSize: "2rem",
              }}
            >
              <PopupCloseButton />
            </div>
          </BackgroundImageFromUrl>
        </div>
      )}

      {showPopupMonthlyExam && (
        <div
          onClick={removePopupOutSide}
          className="modal is-active modal-background"
          style={{ opacity: 1, zIndex: 1000 }}
        >
          <div className="modal-quiz is-relative">
            <h2 style={{ color: "#0A2A66" }} className="title-popup-quiz">
              ! Lưu ý !
            </h2>
            <p className="documnent-quiz">
              Bạn chỉ được làm{" "}
              {listMonthlyExams !== null && listMonthlyExams[0]?.name}{" "}
              <b>một lần duy nhất</b>. <br /> Thời gian làm bài là{" "}
              <b>
                {listMonthlyExams !== null && listMonthlyExams[0]?.examTime}{" "}
                phút
              </b>
              . <br /> Bạn có thật sự muốn bắt đầu?
            </p>

            <div
              className="is-flex is-flex-direction-row"
              style={{ gap: "1rem", margin: "0 auto" }}
            >
              <SecondaryButtonLevelPage onClick={removePopup}>
                Để lúc khác
              </SecondaryButtonLevelPage>
              <PrimaryButtonPageLevel
                onClick={handleClickStartExam}
                style={{ height: "4rem" }}
              >
                {JSON.parse(localStorage.getItem("listExamInfoMonthly"))
                  ?.length > 0 &&
                JSON.parse(localStorage.getItem("listExamInfoMonthly"))[0]
                  ?.takeExamId
                  ? "Làm tiếp"
                  : "Bắt đầu ngay"}
              </PrimaryButtonPageLevel>
            </div>

            <div
              onClick={removePopup}
              className=""
              style={{
                position: "absolute",
                top: "-8%",
                left: "-4%",
                zIndex: 9999,
                background: "transparent",
                border: "none",
                width: "5rem",
                fontSize: "2rem",
              }}
            >
              <PopupCloseButton />
            </div>
          </div>
        </div>
      )}

      {/* <DailyMissionFinished /> */}

      <div className="grade-category is-flex is-relative">
        <div
          className="is-flex is-flex-direction-row is-align-items-center has-background-kurio-main-color-black"
          style={{
            gap: "1rem",
            borderRadius: "1.125rem",
            boxShadow: "0.125rem 0.125rem 0rem 0rem #0A2A66",
          }}
        >
          <p className="has-text-kurio-main-color-white pl-5">
            {selectedName ? selectedName : gradeName}
          </p>
          <div
            className="button-change-grade has-background-kurio-main-color-white has-text-centered has-text-kurio-main-color-black has-text-weight-bold is-uppercase is-flex is-align-items-center is-justify-content-center is-clickable"
            style={{
              width: "10rem",
              border: "0.0625rem solid #0A2A66",
              boxShadow: "0.125rem 0.125rem 0rem 0rem #0A2A66",
              height: "2.5rem",
              fontSize: "1rem",
              borderRadius: "1.25rem",
            }}
            // className={`${showGradeCategory ? "is-active" : ""}`}
            onClick={onSelectButton}
          >
            Đổi Lớp
          </div>
          {showGradeCategory && (
            <BackgroundImageFromUrl
              objectId="admin/frontend-images/auth/bg-desktop-signin.svg"
              className="modal is-active modal-background"
              style={{
                opacity: 1,
                zIndex: 1000,
                width: "100%",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                minHeight: "100vh",
              }}
            >
              <div
                className="is-relative "
                style={{
                  width: "56.875rem",
                  backgroundPosition: "center",
                  height: "38.125rem",
                  borderRadius: "3.8125rem",
                  // overflow:"auto"
                }}
              >
                <div
                  className="is-flex is-flex-direction-column is-align-items-center is-justify-content-space-between "
                  style={{ gap: "2.5rem" }}
                >
                  <div
                    className="has-text-kurio-main-color-white has-text-centered"
                    style={{
                      marginTop: gradeCategory?.length > 5 ? "0rem" : "6.1875rem",
                    }}
                  >
                    <b style={{ fontSize: "2.25rem" }}>Chọn lớp</b>
                  </div>
                  <div
                    className="columns m-0 p-0 is-multiline is-centered"
                    style={{
                      gap: "0.5rem",
                      width: gradeCategory?.length > 5 ? "61rem" : "35.875rem",
                    }}
                  >
                    {gradeCategory &&
                      gradeCategory.length > 0 &&
                      gradeCategory
                        // .filter((item, index) => index !== 8)
                        // .filter((item, index) => index <= 4)
                        .map((grade, index) => (
                          <div key={index}>
                            <SelectGradeButton
                              onClick={() =>
                                handleSelect(grade.name, grade._id)
                              }
                              isClick={selectedId === grade._id}
                            >
                              {grade.name.slice(4)}
                            </SelectGradeButton>
                          </div>
                        ))}
                  </div>
                </div>
              </div>
              <div
                className="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center"
                style={{
                  paddingRight: "2.875rem",
                  width: "100%",
                  position: "fixed",
                  bottom: 0,
                }}
              >
                <img
                  style={{
                    width: "21.5rem",
                    height: "8.3125rem",
                  }}
                  src="/images/auth/logo-signin.svg"
                  alt="loading"
                />
                <PrimaryButtonPageLevel
                  onClick={handleSubmitSelectedGrade}
                  disabled={selectedId.length === 0}
                  style={{ height: "4rem" }}
                  isDarkColor={true}
                >
                  Tiếp Theo
                </PrimaryButtonPageLevel>
              </div>
            </BackgroundImageFromUrl>
          )}
        </div>
      </div>

      <div className="is-relative is-flex">
        <div className="grade-service is-flex">
          {/* <div className="item-grade-service_Union">
            <HelpButton />
          </div> */}

          {listUserInfo !== null ? (
            listUserInfo?.premiumFrom &&
            listUserInfo?.premiumTo &&
            new Date(listUserInfo?.premiumFrom).getTime() <=
              new Date().getTime() &&
            new Date(listUserInfo?.premiumTo).getTime() >
              new Date().getTime() ? (
              ""
            ) : (
              <div
                onClick={() => navigate("/upgrade")}
                className="item-grade-service_noti"
              >
                <img src="/images/hub/btn-premium.svg" alt="" />
              </div>
            )
          ) : (
            ""
          )}
          <div
            className="is-clickable is-flex is-align-items-center is-justify-content-center"
            style={{
              borderRadius: "1rem",
              width: "3.5rem",
              height: "3.5rem",
              background: "linear-gradient(135deg, #00A79B 0%, #2162C0 100%)",
            }}
            onClick={() => navigate("/voucher")}
          >
            <img
              style={{ objectFit: "cover" }}
              src="/images/voucher/icon-voucher.svg"
              alt=""
            />
          </div>

          <div
            onClick={handleClickShowPopupDailyMission}
            className={` item-grade-service_noti`}
          >
            <NotificationsButton />
          </div>

          <div
            onClick={onHandleClick}
            className={`${showAccMenu ? "is-active" : ""} dropdown`}
          >
            <div className="dropdown-trigger">
              <div
                style={{
                  background: "transparent",
                  border: "none",
                  boxShadow: "none",
                }}
              >
                <div
                  onClick={onHandleClick}
                  // onMouseMove={onMouseMove}
                  // onMouseOut={onMouseOut}
                  style={
                    defaultAccMenu
                      ? { backgroundColor: "#E9EEF8" }
                      : hoverAccMenu
                      ? { backgroundColor: "#0A2A66" }
                      : showAccMenu
                      ? { backgroundColor: "#0A2A66" }
                      : { backgroundColor: "#E9EEF8" }
                  }
                  className={
                    listUserInfo?.fullName.split(" ")[
                      listUserInfo?.fullName.split(" ").length - 1
                    ]?.length >= 7
                      ? "item-grade_user-information is-flex"
                      : "item-grade_user-information-fix is-flex"
                  }
                >
                  {listUserInfo?.profileImage &&
                  listGradeUnit !== null &&
                  listUserInfo?.profileImage?.length > 0 ? (
                    <ImageFromUrl
                      objectId={
                        Array.isArray(image)
                          ? image?.join(" ")
                            ? image?.join(" ")
                            : listUserInfo.profileImage
                          : image?.length > 0
                          ? image
                          : listUserInfo.profileImage
                      }
                      alt=""
                      style={{
                        objectFit: "cover",
                        width: "2.5rem",
                        height: "2.5rem",
                        borderRadius: "50%",
                      }}
                    />
                  ) : (
                    <ImageFromUrl
                      objectId="admin/frontend-images/avatar/chim-bt.svg"
                      style={{
                        objectFit: "cover",
                        width: "2.5rem",
                        height: "2.5rem",
                        borderRadius: "50%",
                      }}
                      alt="loading"
                    />
                  )}
                  <p
                    style={
                      defaultAccMenu
                        ? { color: "#0A2A66" }
                        : hoverAccMenu
                        ? { color: "#FFFFFF" }
                        : showAccMenu
                        ? { color: "#FFFFFF" }
                        : { color: "#0A2A66" }
                    }
                  >
                    Xin chào, <br />{" "}
                    <span
                      style={
                        listUserInfo?.fullName.split(" ")[
                          listUserInfo?.fullName.split(" ").length - 1
                        ]?.length >= 6
                          ? {
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: "4.4rem",
                              display: "block",
                            }
                          : {}
                      }
                    >
                      {
                        listUserInfo?.fullName.split(" ")[
                          listUserInfo?.fullName.split(" ").length - 1
                        ]
                      }
                    </span>
                  </p>
                  <div className="is-flex is-align-items-center is-justify-content-center">
                    <img
                      src={
                        showAccMenu
                          ? "/images/menu/vector-click.svg"
                          : "/images/menu/vector.svg"
                      }
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="accmenu__button-click dropdown-menu"
              id="dropdown-menu"
              role="menu"
            >
              <ul>
                <li onClick={() => handleClick("/account")}>
                  <img src="/images/menu/icon-information.svg" alt="" /> Tài
                  khoản
                  {`${window.location.pathname}` === "/account" && (
                    <ImageFromUrl
                      style={{
                        width: "0.5rem",
                        height: "0.5rem",
                        marginLeft: "2.875rem",
                      }}
                      objectId="admin/frontend-images/exam/icon-active.svg"
                      alt=""
                    />
                  )}
                </li>
                <li onClick={() => handleClick("/profile")}>
                  <ImageFromUrl
                    objectId="admin/frontend-images/menu/icon-contact.svg"
                    alt=""
                  />{" "}
                  Sổ liên lạc
                  {`${window.location.pathname}` === "/profile" && (
                    <ImageFromUrl
                      style={{
                        width: "0.5rem",
                        height: "0.5rem",
                        marginLeft: "2.875rem",
                      }}
                      objectId="admin/frontend-images/exam/icon-active.svg"
                      alt=""
                    />
                  )}
                </li>
                <li onClick={() => handleClick("/achievement")}>
                  <img src="/images/menu/icon-achievement.svg" alt="" /> Thành
                  tích
                  {`${window.location.pathname}` === "/achievement" && (
                    <ImageFromUrl
                      style={{
                        width: "0.5rem",
                        height: "0.5rem",
                        marginLeft: "2.875rem",
                      }}
                      objectId="admin/frontend-images/exam/icon-active.svg"
                      alt=""
                    />
                  )}
                </li>
                <hr
                  className="m-0"
                  style={{ border: "1px solid #0A2A66", height: "0rem" }}
                />
                <li
                  onClick={() => handleClick("/voucher")}
                  className="has-text-kurio-main-color-white"
                  style={{
                    borderRadius: "1rem",
                    background:
                      "linear-gradient(135deg, #00A79B 0%, #2162C0 100%)",
                  }}
                >
                  <img
                    style={{ width: "0.9785rem" }}
                    src="/images/voucher/icon-voucher.svg"
                    alt=""
                  />{" "}
                  Mã Voucher
                  {`${window.location.pathname}` === "/voucher" && (
                    <div
                      className="has-background-kurio-main-color-white"
                      style={{
                        width: "0.5rem",
                        height: "0.5rem",
                        marginLeft: "1.5rem",
                        borderRadius: "1rem",
                      }}
                      // objectId="admin/frontend-images/exam/icon-active.svg"
                      alt=""
                    ></div>
                  )}
                </li>
                <li
                  onClick={() => handleClick("/user-upgrade")}
                  className="has-text-kurio-main-color-white"
                  style={{
                    borderRadius: "1rem",
                    background:
                      "linear-gradient(115.61deg, #FEAC31 20.81%, #EF7642 60.84%, #AF522A 88.23%)",
                  }}
                >
                  <img src="/images/menu/icon-upgrade.svg" alt="" /> Nâng cấp
                  {`${window.location.pathname}` === "/upgrade" && (
                    <div
                      className="has-background-kurio-main-color-white"
                      style={{
                        width: "0.5rem",
                        height: "0.5rem",
                        marginLeft: "2.875rem",
                        borderRadius: "1rem",
                      }}
                      // objectId="admin/frontend-images/exam/icon-active.svg"
                      alt=""
                    ></div>
                  )}
                </li>
                <li onClick={handleLogout}>
                  <img src="/images/menu/icon-out.svg" alt="" /> Đăng xuất
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    listGradeUnit: state.grades.grades,
    questionDaily: state.dailyMission.questions,
    startHP: state.dailyMission.startingHp,
    currentHP: state.dailyMission.currentHp,
    lastLesson: state.grades.lastlesson,
    gradeId: state.grades.gradeId,
    gradeName: state.grades.gradeName,
    listUserInfo: state.grades.listUserInfo,
    errorGrade: state.grades.error,
    gradeCategory: state.grades.gradeCategory,
    isChangeGrade: state.grades.isChangeGrade,
    imageUrls: state.dailyMission.imageUrls,
    deadline: state.examsQuestion.checkExamTime,
    serverTime: state.examsQuestion.serverTime,
    listMonthlyExams: state.examsQuestion.listMonthlyExams,
    sections: state.questions.sections,
  };
}

export default connect(mapStateToProps)(MenuTopBar);
